<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <template #cell(userNumber)="data">
            <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="data.toggleDetails">{{data.item.userNumber }}</b-button>
        </template>

        <template #row-details="data">
            <b-row>
                <b-col> 
                    <b-card border-variant="success" title="Genel Detay" class="card-company-table">
                        <p><strong>Puan : </strong>{{data.item.point}}</p>
                        <p><strong>Kurum Sırası : </strong>{{data.item.institutionOrder}}</p>
                        <p><strong>Sınıf Sırası : </strong>{{data.item.classOrder}}</p>
                        <p><strong>Genel Sırası : </strong>{{data.item.generalOrder}}</p>
                    </b-card>
                </b-col>
                <b-col> 
                    <b-card border-variant="success" title="Sayısal Detay" class="card-company-table">
                        <p><strong>Puan : </strong>{{data.item.digitalPoint}}</p>
                        <p><strong>Kurum Sırası : </strong>{{data.item.digitalInstitutionOrder}}</p>
                        <p><strong>Sınıf Sırası : </strong>{{data.item.digitalClassOrder}}</p>
                        <p><strong>Genel Sırası : </strong>{{data.item.digitalGeneralOrder}}</p>
                    </b-card>
                </b-col>
                <b-col> 
                    <b-card border-variant="success" title="Eşit Ağırlık Detay" class="card-company-table">
                        <p><strong>Puan : </strong>{{data.item.eaPoint}}</p>
                        <p><strong>Kurum Sırası : </strong>{{data.item.eaInstitutionOrder}}</p>
                        <p><strong>Sınıf Sırası : </strong>{{data.item.eaClassOrder}}</p>
                        <p><strong>Genel Sırası : </strong>{{data.item.eaGeneralOrder}}</p>
                    </b-card>
                </b-col>
                 <b-col> 
                    <b-card border-variant="success" title="Sözel Detay" class="card-company-table">
                        <p><strong>Puan : </strong>{{data.item.verbalPoint}}</p>
                        <p><strong>Kurum Sırası : </strong>{{data.item.verbalInstitutionOrder}}</p>
                        <p><strong>Sınıf Sırası : </strong>{{data.item.verbalClassOrder}}</p>
                        <p><strong>Genel Sırası : </strong>{{data.item.verbalGeneralOrder}}</p>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-table :items="data.item.lessons" responsive class="mb-0" :fields="lessonFields"></b-table>
            </b-row>
        </template>

    </b-table>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BRow,
    BCol
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        BRow,
        BCol,
        flatPickr,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'userClass', label: 'Sınıfı' },
                { key: 'userNumber', label: 'Numarası' },
                { key: 'userName', label: 'Adı Soyadı' },
                { key: 'point', label: 'YKS-TYT Puanı' },
                { key: 'digitalPoint', label: 'Yks Sayısal Puanı' },
                { key: 'eaPoint', label: 'Yks Eşit Ağırlık Puanı' },
                { key: 'verbalPoint', label: 'Yks Sözel Puanı' },
                { key: 'institutionOrder', label: 'Kurum Sırası' },
                { key: 'classOrder', label: 'Sınıf Sırası' },
                { key: 'generalOrder', label: 'Genel Sırası' },
            ],
            lessonFields:[
                { key: 'name', label: 'Ders' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'net', label: 'Net Sayısı' }
            ],
            users: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Report/ReportCardItem/" + this.$route.params.id);
            this.users = users.data.data;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
